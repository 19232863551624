import React from 'react'
import Header from "../components/header"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import Footer from "../components/footer"
import { graphql } from "gatsby"

const Magazines = (props) => {
  const magazines = props.pageContext.magazines;

  if(!magazines)
    return null

  return (
    <main style={{backgroundColor: '#fff'}}>
      <Header allMagazines={true} {...props} />

      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {magazines && magazines.map((item, key) => {
              return (
                <div key={key} className="col-lg-3 col-md-4">
                  <AniLink cover direction={"left"} bg={item.coverImageMainColour} className={"article overflow-h"} to={'/magazine/' + item.slug} key={key}>
                    <div style={{position: 'relative'}} className="cover magazine-cover">
                      <picture><img alt={''} src={item.coverImage ? item.coverImage :  "https://dummyimage.com/323x436"} /></picture>

                      <div className="cover" style={{backgroundColor: '#000000'}}><p style={{maxWidth: '90%', textAlign: 'center'}}>{item.title}</p></div>
                    </div>

                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Footer {...props} />
    </main>
  )
}

export const query = graphql`
  query {
    allMagazines {
      nodes {
        title,
        slug,
        magazineNumber,
        coverImageMainColour
      }
    }
  }
`

export default Magazines